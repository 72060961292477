export enum REWARDS_BRANDING_THEME_COLOR_TYPES {
    primaryColor = "primaryColor",
    secondaryColor = "secondaryColor",
}

export enum REWARDS_BRANDING_FONT_COLOR_TYPES {
    white = "#ffffff",
    black = "#1a1a1a",
}

export enum REWARDS_BRANDING_WIDGET_POSITION_TYPES {
    bottomRight = "BR",
    bottomLeft = "BL",
}

export enum REWARDS_BRANDING_WIDGET_STYLE_TYPES {
    iconWithText = "iconWithText",
    textOnly = "textOnly",
    iconOnly = "iconOnly",
}

export enum REWARDS_BRANDING_WIDGET_LAYOUT_TYPES {
    home = "home",
    waysToEarnPoints = "waysToEarnPoints",
    waysToSpendPoints = "waysToSpendPoints",
    vipTiers = "vipTiers",
}

export enum REWARDS_BRANDING_WIDGET_ICON_TYPES {
    default = "default",
    gift = "gift",
    ticket = "ticket",
    star = "star",
    ribbon = "ribbon",
}
