import { enums, nullable, object, string } from "superstruct";

import {
    REWARDS_BRANDING_FONT_COLOR_TYPES,
    REWARDS_BRANDING_THEME_COLOR_TYPES,
    REWARDS_BRANDING_WIDGET_ICON_TYPES,
    REWARDS_BRANDING_WIDGET_LAYOUT_TYPES,
    REWARDS_BRANDING_WIDGET_POSITION_TYPES,
    REWARDS_BRANDING_WIDGET_STYLE_TYPES,
} from "../constants";

const BrandingThemeColorSchema = enums([
    REWARDS_BRANDING_THEME_COLOR_TYPES.primaryColor,
    REWARDS_BRANDING_THEME_COLOR_TYPES.secondaryColor,
]);

const BrandingFontColorSchema = enums([
    REWARDS_BRANDING_FONT_COLOR_TYPES.white,
    REWARDS_BRANDING_FONT_COLOR_TYPES.black,
]);

const RewardsBrandingSchema = object({
    rewardsPopUpWidget: object({
        programName: string(),
        theme: object({
            primaryColor: string(),
            secondaryColor: string(),
        }),
        banner: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: BrandingFontColorSchema,
            image: nullable(string()),
        }),
        buttons: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: BrandingFontColorSchema,
        }),
        content: object({
            iconsThemeColor: BrandingThemeColorSchema,
            linksThemeColor: BrandingThemeColorSchema,
        }),
        launcher: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: BrandingFontColorSchema,
            widgetStyle: enums([
                REWARDS_BRANDING_WIDGET_STYLE_TYPES.iconWithText,
                REWARDS_BRANDING_WIDGET_STYLE_TYPES.textOnly,
                REWARDS_BRANDING_WIDGET_STYLE_TYPES.iconOnly,
            ]),
            widgetPosition: enums([
                REWARDS_BRANDING_WIDGET_POSITION_TYPES.bottomRight,
                REWARDS_BRANDING_WIDGET_POSITION_TYPES.bottomLeft,
            ]),
            widgetLayout: enums([
                REWARDS_BRANDING_WIDGET_LAYOUT_TYPES.home,
                REWARDS_BRANDING_WIDGET_LAYOUT_TYPES.waysToEarnPoints,
                REWARDS_BRANDING_WIDGET_LAYOUT_TYPES.waysToSpendPoints,
                REWARDS_BRANDING_WIDGET_LAYOUT_TYPES.vipTiers,
            ]),
            iconType: enums([
                REWARDS_BRANDING_WIDGET_ICON_TYPES.default,
                REWARDS_BRANDING_WIDGET_ICON_TYPES.gift,
                REWARDS_BRANDING_WIDGET_ICON_TYPES.ticket,
                REWARDS_BRANDING_WIDGET_ICON_TYPES.star,
                REWARDS_BRANDING_WIDGET_ICON_TYPES.ribbon,
            ]),
            iconColor: BrandingFontColorSchema,
        }),
    }),
});

export default RewardsBrandingSchema;
